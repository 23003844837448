.result .toggle-show-comments {
  text-align: right;
}
.result blockquote {
  font-size: 15px;
}
.result blockquote p {
  padding: 4px;
  border-radius: 2px;
  margin-bottom: 10px;
}
.result .song-iframe-outer {
  padding: 20px 0;
}

.song .song-picture {
  min-height: 300px;
}

span.by {
  color: #666;
}

.related li span {
  color: #666;
}

.related ul {
  padding-left: 20px;
  list-style-image: url(./static/bullet.png);
}

.related li {
  padding-bottom: 5px;
}

.not-found {
  text-align: center;
}

.amazon-item img.media-object {
  max-width: 100px;
}
.amazon-item .price {
  color: #b12704;
  margin-right: 5px;
}

.youtube,
.spotify {
  margin-top: 40px;
}
