/* Sticky footer styles
-------------------------------------------------- */

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  /*background-color: #f5f5f5;*/
}

/* Custom page CSS
-------------------------------------------------- */

.page-header h1 {
  text-align: center;
  font-weight: bold;
}
.page-header h1 a {
  color: #333;
  text-decoration: underline;
}
/* .page-header h1 a:hover {
  text-decoration: none;
} */
.page-header h5 {
  text-align: center;
}
/* Not required for template or sticky footer method. */

.container {
  width: auto;
  max-width: 680px;
  padding: 0 10px !important;
  margin-right: auto;
  margin-left: auto;
}
/* Desktop */
@media (min-width: 1025px) {
  .container {
    padding: 0 15px;
  }
}
.container .text-muted {
  margin: 20px 0;
}

footer a.active {
  font-weight: bold;
}

.loading {
  margin-top: 50px;
}
.loading p {
  text-align: center;
  opacity: 0.7;
  padding-top: 5px;
}

/* START CSS ANIMATION */
/* From http://cssload.net/en/miscellaneous/2 */

.cssload-square {
  width: 11px;
  height: 7px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: rgb(115, 179, 255);
  animation: cssload-grow 3.45s infinite cubic-bezier(0.53, 0.68, 0.53, 0.41);
  -o-animation: cssload-grow 3.45s infinite cubic-bezier(0.53, 0.68, 0.53, 0.41);
  -ms-animation: cssload-grow 3.45s infinite
    cubic-bezier(0.53, 0.68, 0.53, 0.41);
  -webkit-animation: cssload-grow 3.45s infinite
    cubic-bezier(0.53, 0.68, 0.53, 0.41);
  -moz-animation: cssload-grow 3.45s infinite
    cubic-bezier(0.53, 0.68, 0.53, 0.41);
}
.cssload-square:nth-of-type(2) {
  margin-top: 9px;
  animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  -ms-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
}
.cssload-square:nth-of-type(3) {
  margin-top: 18px;
  animation-delay: 0.23s;
  -o-animation-delay: 0.23s;
  -ms-animation-delay: 0.23s;
  -webkit-animation-delay: 0.23s;
  -moz-animation-delay: 0.23s;
}
.cssload-square:nth-of-type(4) {
  margin-top: 27px;
  animation-delay: 0.35s;
  -o-animation-delay: 0.35s;
  -ms-animation-delay: 0.35s;
  -webkit-animation-delay: 0.35s;
  -moz-animation-delay: 0.35s;
}
.cssload-square:nth-of-type(5) {
  margin-top: 36px;
  animation-delay: 0.46s;
  -o-animation-delay: 0.46s;
  -ms-animation-delay: 0.46s;
  -webkit-animation-delay: 0.46s;
  -moz-animation-delay: 0.46s;
}
.cssload-wrapper {
  width: 37px;
  height: 73px;
  position: absolute;
  left: 50%;
  margin-top: -75px;
  margin-left: -19px;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  animation: 2.3s cssload-global infinite;
  -o-animation: 2.3s cssload-global infinite;
  -ms-animation: 2.3s cssload-global infinite;
  -webkit-animation: 2.3s cssload-global infinite;
  -moz-animation: 2.3s cssload-global infinite;
}
@keyframes cssload-grow {
  0%,
  100% {
    transform: translateX(-19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  25% {
    transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
  50% {
    transform: translateX(19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  75% {
    transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
}
@-o-keyframes cssload-grow {
  0%,
  100% {
    -o-transform: translateX(-19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  25% {
    -o-transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
  50% {
    -o-transform: translateX(19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  75% {
    -o-transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
}
@-ms-keyframes cssload-grow {
  0%,
  100% {
    -ms-transform: translateX(-19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  25% {
    -ms-transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
  50% {
    -ms-transform: translateX(19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  75% {
    -ms-transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
}
@-webkit-keyframes cssload-grow {
  0%,
  100% {
    -webkit-transform: translateX(-19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  25% {
    -webkit-transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
  50% {
    -webkit-transform: translateX(19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  75% {
    -webkit-transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
}
@-moz-keyframes cssload-grow {
  0%,
  100% {
    -moz-transform: translateX(-19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  25% {
    -moz-transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
  50% {
    -moz-transform: translateX(19px) scaleX(0.5);
    background: rgb(115, 179, 255);
  }
  75% {
    -moz-transform: translateX(0px) scaleY(1) scaleX(5) rotateY(180deg);
    background: rgb(240, 192, 120);
  }
}
@keyframes cssload-global {
  0%,
  100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(360deg);
  }
}
@-o-keyframes cssload-global {
  0%,
  100% {
    -o-transform: rotateY(0deg);
  }
  50% {
    -o-transform: rotateY(360deg);
  }
}
@-ms-keyframes cssload-global {
  0%,
  100% {
    -ms-transform: rotateY(0deg);
  }
  50% {
    -ms-transform: rotateY(360deg);
  }
}
@-webkit-keyframes cssload-global {
  0%,
  100% {
    -webkit-transform: rotateY(0deg);
  }
  50% {
    -webkit-transform: rotateY(360deg);
  }
}
@-moz-keyframes cssload-global {
  0%,
  100% {
    -moz-transform: rotateY(0deg);
  }
  50% {
    -moz-transform: rotateY(360deg);
  }
}

/* END CSS ANIMATION */

/* START CSS ANIMATION */
/* FROM http://tobiasahlin.com/spinkit/ */
/* .sk-fading-circle {
  margin: 0;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
} */
/* END CSS ANIMATION */

.related {
  margin-top: 40px;
}

.amplifiedouter {
  margin: 40px 0;
}
